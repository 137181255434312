<template>

  <div>
    <div class="page-banner">
      <img v-lazy="banner" class="img-center">
    </div>
    <solution-common :newsItem="newsItem"/>
  </div>
</template>
<script>

import solutionCommon from "@/views/solutionDetail/solutionCommon";
import {getCase} from "@/network/main";



export default {
  name: "solutionCase",
  components:{
    solutionCommon
  },
  data() {
    return {
      id: null,
      banner:{},
      newsItem:{},
    }
  },
  created() {
    this.id = this.$route.params.id; //拿到id
    getCase(this.id).then(res => {
      this.banner = res.data.data.banner;
      this.newsItem = res.data.data.case_study;
    })
  },

}
</script>

<style scoped>

</style>