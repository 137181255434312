<template>
  <div class="container padding-80">
    <div class="solution-more">
      <h1>{{ newsItem.title }}</h1>
      <img :src="newsItem.info_image">
      <div class="solution-more-txt">
        <p>
          {{ newsItem.content }}
        </p>
      </div>
      <div class="back">
        <div class="common-btn">
          <router-link to="/solution">Back</router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "solutionCommon",
  props:{
    newsItem: {
      default() {
        return {}
      }
    }
  },

}
</script>

<style scoped>
.solution-more h1{
  padding-bottom: 20px;
}
.solution-more-txt{
  padding: 20px 0;
}
.back{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .solution-more h1{
    font-size: 18px;
  }
}


</style>